.binary_tooltip {
  border-radius: 2px;
  width: 100%;
  z-index: 9999999;
  display: inline;

  span {
    border-radius: 2px;
  }
}

.binary_tooltip_overlay {
  z-index: 2000;
  height: auto !important;
  font-size: 1em;
  padding: 8px 10px !important;
}
