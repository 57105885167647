.binary_filter_textbox_range_container {
  padding: 12px;
  width: 96%;
  max-height: 290px;
  border: 1px solid #CCC;
  overflow: auto;
  overflow-x: hidden;
  margin-left: 10px;

  .binary_filter_textbox_from, .binary_filter_textbox_to {
    width: 60px !important;
  }

  span {
    margin: 0 0 0 10px;
    font-weight: 600;
  }
}