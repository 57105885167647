.binary_spinner {
  width: 100%;
  height: 100%;
  min-height: 650px;
  opacity: 0.5;
  background-color: #FFF;
  right: 0;
  left: 0;
  position: fixed;
  top: 0 !important;

  & > div {
    top: 40%;
    left: 50%;
  }
}


.binary_spinner_inline {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #FFF;
  right: 0;
  left: 0;
  top: 0 !important;

  & > div {
    top: 40%;
    left: 50%;
  }
}