.binary_panel_container {

  &:after {
    content:'';
    display:block;
    clear: both;
  }

  .binary_panel {
    a:hover, a:focus {
      text-decoration: none;
    }

    i {
      float: right;
    }
  }
}
