.binary_button_container {
  width: 100%;
  border-radius: 2px;
  display: inline;
  overflow: visible;

  &:after {
    clear: both;
  }

  .raised {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  }

  .no_raised {
    &:hover {
      opacity: 0.9;
    }
  }

  .binary_button {
    cursor: pointer;
    border-radius: 2px;
    min-height: 36px;
    color: #000;
    padding: 0px 15px;
    background: #FFF !important;
    border: 1px #CCC solid;
    background-size: 100%;
    position: relative;
    min-width: 90px;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    color: #666666;
    font-size: 1em;

    &[disabled] {
      background: none rgb(229, 229, 229) !important;
    }

    &:focus {
      outline: none;
    }

    &:active {
      outline: none;
    }

    &::-moz-focus-inner {
      border: 0;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
      background-repeat: no-repeat;
      background-position: 50%;
      transform: scale(10,10);
      opacity: 0;
      transition: transform .5s, opacity 1s;
    }

    &:active:after {
      transform: scale(0,0);
      opacity: .2;
      transition: 0s;
    }

    i {
      font-size: 1.2em;
      margin-right: 10px;
    }
  }

  button {
    &.binary_button_icon {
      margin-bottom: 10px;
      width: 100%;
      background-color: #FFF;
      border: 1px solid #CCC;

      &:hover {
        background-color: #F8F8F8 !important;
      }

      span {
        margin-left: 5px;
        font-size: 15px;
        font-weight: 100;
      }
    }
  }

  .binary_button_indicator {
    position: absolute;
    font-size: 26px;
    color: #FF8800 !important;
    right: 10px;
    bottom: 30px;
  }

  .binary_button_number, .binary_button_number_single {
    position: absolute;
    color: #FFF !important;
    margin-top: 10px;
    font-weight: 400 !important;
    font-size: 0.5em;
  }

  .binary_button_number {
    margin-left: -18px;
  }

  .binary_button_number_single {
    margin-left: -16px;
  }

}
