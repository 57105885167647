.binary_accordion {
}
.binary_accordion_section {
}

.binary_accordion_section > h3 {
  cursor: pointer;
}
.binary_accordion_section > .body {
  height: 0;
  padding: 0 10px;
  overflow-y: hidden;
  transition: height .2s;
  transition: height .2s,
  padding-top .2s,
  padding-bottom .2s;
}
.binary_accordion_section.selected > .body {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
