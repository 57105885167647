.widget_container {
  margin: 0;
  .widget_container_title {
    text-align: left;
    padding: 0 0 0 0.5em;
    display: flex;

    .left {
      flex: 1;
      text-align: left;
    }

    .right {
      flex: 1;
      text-align: right;
    }

    h4 {
      font-size: 1.2em;
      font-weight: 900;
      margin-bottom: 0;
    }
  }

  .widget_container_content {
    border: 1px #CCC solid;
    border-top: 2px #000 solid;
    margin: 0.4em;
  }
}