.binary_popover_header {
  padding: 0 !important;
}

.binary_comment_number {
  position: absolute;
  margin-left: -16px;
  color: #FFF;
  margin-top: 7px;
  font-size: 0.5em;
  font-weight: 600;
}


.binary_comment_circle {
  position: absolute;
  left: 12px;
  color: #FF8800;
  bottom: 11px;
}

.binary_popover_content {
  max-height: 650px;
  overflow: auto;
  overflow-x: hidden;
}