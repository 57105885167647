.binary_left_pannel_container {
	padding: 10px 10px;
    overflow-x: hidden;
    color: #4e4e4e !important;
    height: 100%;
}

.binary_left_pannel_title {

  position: relative;
  margin-top: 0px;
  border-bottom: 1px solid #979797;

  h3 {
    padding: 0px;
    margin-top: 10px;
    font-size: 1.6em;
    text-align: left;
  }
}

.binary_left_pannel_nav {
  float: right;
}

.binary_left_pannel_body {
  margin: 0px;
}