.binary_table {
  margin: 0;
  border-bottom: 1px #CCC solid;
  .inner_html_container {
    margin: 0;
    display: flex;
    border-top: 1px solid #fff;
    .inner_html {
      flex-basis: 100%;
    }
  }
}

.binary_table_title {
  h4 {
    font-size: 15px;
    font-weight: 400;
    margin: 6px 0px;
  }
  background: #04576B;
  color: #FFF;
  margin: 0;
  border-radius: 0;
  left: 0;
  width: 100%;
  padding: 5px;
  right: 0;
}

.binary_table_item {
  padding: 10px 20px;
  border-right: 1px solid #CCC;
  border-left: 1px solid #CCC;

  .binary_table_item_col {
    // white-space: nowrap;
    // overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0;
  }

  &:hover {
    background-color: rgba(4, 87, 106, 0.16) !important;
  }

  h3 {
    color: #666;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
    padding: 1px 0;
  }

  &:nth-child(even) {
    background-color: #EEECEC;
  }
}

.NoDataAvailable {
  width: 80%;
  height: auto;
  margin: 5% 10% 0 10%;

  i {
    font-size: 8em;
  }
}

.NoDataAvailableCustom {
  width: 100%;
  height: auto;
  margin: 0 1% 0 0;
}
