.img {
  width: 100%;
  max-width: 500px;
}

.round {
  border-radius: 50%;
}

.clickable {
  cursor: pointer;
}

.overlay {
  overflow: hidden;
}