.sort_item {
	margin: 0;
	padding: 6px 10px;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}

	i {
		margin-left: 2px;
	}
}