.binary_dialog_close_button {
  float: right;
  top: 0px;
  right: 0px;
  z-index: 99999;
  position: absolute;
}

.binary_dialog_button {
  margin: 20px 10px 0px 10px;
  bottom: 10px;
  text-align: right;
}

.binary_dialog_action_button {
  margin-left: 10px;
  display: inline;
}
