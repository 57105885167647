.macro_container { 
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  position: fixed;
  height: 250px;
  width: 200px;
  color: #000080; 
  z-index: 999999;
}

.binary_macro_item {
  height: 25px;
  font-size: 12px !important;
  color: #000080 !important;
  
  div {
    line-height: 25px !important;
  }
}