.binary_auto_complete_container {
  padding: 4px 15px;
  font-size: 22px;
  min-height: 50px;

  i {
    margin-top: 5px;
    font-size: 18px;
    margin-right: 4px;
  }

  h5 {
    margin-top: 20px;
  }

  & > div:nth-child(2) {
    & > div {
      font-size: 14px !important;
    }
  }

  hr {
    width: 150px !important;
    border-bottom: 1px solid #CCC !important;
  }
}

.binary_auto_complete_img {
  max-width: 20%;
  margin-top: 10px;
  max-height: 50px;
}

.binary_auto_complete_main_img {

  display: inline;
  img {
    width: 30px;
    height: 30px;
    display: inline;
  }
}

.show {
  display: block;
}

.hide {
  display: none;
}

.binary_auto_complete_comment {
  width: 100%;

  textarea {
    width: 100%;
    resize: none;
    padding: 10px;
    border: 1px solid #ccc;
    height: 150px;
  }

  .binary_auto_complete_buttons {
    position: relative !important;

    .select, .unselect {
      display: block;
      margin-left: 2px;
      width: 38%;
    }

  }
}

.binary_auto_complete_buttons {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 99999;

  .select {
    width: 40%;
    cursor: pointer;
    float: right;
    margin-right: 4px;
    color: #10881F;
    background-color: #F8F8F8;
    border: 1px solid #CCC;
    padding: 0px 0px 0px 4px;
    border-radius: 5px;
    display: none;
    font-size: 15px;
  }

  .unselect {
    width: 40%;
    cursor: pointer;
    float: right;
    color: #FF0000;
    background-color: #F8F8F8;
    border: 1px solid #CCC;
    padding: 0px 14px 0px 4px;
    border-radius: 5px;
    display: none;
    font-size: 16px;
  }

  &:after {
    clear: both;
  }
}
