.binary_text_box {
  width: 100% !important;
  min-height: 150px;
  margin-bottom: 30px;

  div {
    bottom: 0px !important;
  }

  label {
    top: 5px !important;
    left: 5px !important;
    font-size: 12px;
  }

  hr {
    bottom: 0 !important;
  }

  textarea {
    border: 1px solid #CCC !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 12px !important;
    font-size: 14px !important;
  }
}

.disabled {
  hr {
    border-bottom: none !important;
  }
}

.binary_input {
  width: 100% !important;
  margin-bottom: 40px;
  margin-top: 20px;

  div {
    bottom: 0px !important;
  }

  label {
    top: 5px !important;
    left: 5px !important;
    font-size: 12px;
  }

  hr {
    bottom: 0 !important;
  }

  input {
    border: 1px solid #CCC !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 0px 12px !important;
    font-size: 14px !important;
  }
}

.autocomplete_container {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #FFF;
  border: 1px solid #CCC;
  z-index: 99999;
  min-height: 90px;

  .autocomplete_item {
    display: block;
    cursor: pointer;
    padding: 5px;

    strong {
      font-weight: 900;
      text-decoration: underline;
    }
  }

  .autocomplete_item:hover {
    background-color: #E0ECF8;
  }
}

.binary_richtext_wrapper {
  margin-bottom: 30px;
}

.binary_richtext_toolbar {
  margin-bottom: 5px;
  border-radius: 0;
  border-color: #CCC;
  width: 100%;
}

.binary_richtext_editor {
  border: 1px solid #CCC;
  padding: 10px;
  font-size: 13px;
  height: 150px;
}

.rte_error {
  position: absolute;
  bottom: 10px;
  font-size: 12px;
  color: red;
}
