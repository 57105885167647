.binary_menu {
	width: 100%;
	height: auto;
	padding: 8px 0px 0px 0px !important;
}

.binary_menu_item, .binary_menu_item_child {
	width: 100%;

	& > div > div {
		padding: 0 5px !important;
	}
}

.binary_menu_item_child {
	& > div > div {
		padding: 0 15px !important;
	}
}

.binary_menu_divider {
	margin: 2px 0px 2px !important;
}

.binary_menu_item_nested {
	width: 100%;

	& > div > div {
		display: block !important;
	}
}