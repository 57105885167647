.side_tab_container {
  min-width: 100px;
  min-height: 40px;
  display: block;
  vertical-align: middle;
  margin: 5px 0px 5px 0px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 6px 0px;
  cursor: pointer;

  h5 {
    color: #4e4e4e;

    i {
      float:right;
    }
  }
}
.selected {
  background-color: rgba(4, 87, 106, 0.16) !important;
}
.side_tab_wrapper {
  margin-bottom: 10px;
}
.side_tab_content {
  h5 {
    font-weight: 200 !important;
  }
}

.error {
  border: 1px solid red;
}