.binary_breadcrum_container {

  width: 95%;
	margin: 15px 0px;

	.binary_breadcrum_item, .binary_breadcrum_item_last {
    color: #6090C3;
    cursor: pointer;
    font-size: 11px;
    margin-right: 15px;
	}

  .binary_breadcrum_item_last {
    color: #000;
  }

	i {
    color: #4E4E4E;
    line-height: 9px;
    position: absolute;
    margin-top: 7px;
    margin-left: 5px;
    font-size: 6px;
  }
}
