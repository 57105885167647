.binary_radiobox_container {
  padding: 12px 20px;
  width: 98%;
  max-height: 280px;
  border: 1px solid #CCC;
  overflow: auto;
  overflow-x: hidden;
  margin-left: 5px;
  margin-top: 25px;
}


.binary_filter_search {

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  i {
    float: left;
    background: #EEE;
    color: #666;
    border: 1px #CCC solid;
    border-right: none;
    width: 8%;
    padding: 0.68em;
  }

  input {
    width: 92%;
    padding: 0.5em;
    border-radius: 0px;
    border: 1px #CCC solid;
  }
}
