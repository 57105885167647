.binary_button_close {
  position: relative;
  color: #4e4e4e;
  font-size: 2em;
  cursor: pointer;
  border-radius: 30px;
  display: inline-block;
  line-height: 0px;
  padding: 0px 2px;
  top: -2px;

  i {
    font-size: 1.2em;
    color: #4e4e4e;
  }
}

.binary_button_close_border {
  position: relative;
  color: #000;
  font-size: 2em;
  cursor: pointer;
  border-radius: 30px;
  display: inline-block;
  line-height: 0px;
  padding: 0px 2px;
  background: #E8E8E8;
  border-radius: 25px;
  border: 1px solid #CCC;
}