.binary_navigation_item {
  margin-top: 6px;
  font-weight: 900;
  height: 52px;
  float: right !important;

  .binary_navigation_nav {
    a {
      color: #666;
      font-weight: 800;
    }

    a:hover {
      color: #000;
      font-weight: 800;
    }
  }

  .binary_navigation_sub {
    a {
      color: #666;
      font-weight: 800;
    }

    a:hover {
      background-color: rgba(4, 87, 106, 0.16) !important;
    }
  }

  .binary_navigation_highlight {
    a {
      color: #000 !important;
      font-weight: 800;
    }
  }

  .binary_navigation_sub_highlight {
    background: #E0ECF8;
    a {
      color: #666;
      font-weight: 800;
    }
  }

  .binary_navigation_nav_noarrow {
    & > a > span {
      display: none;
    }

    & > a {
      background-color: #f8f8f8;
    }
  }

  .no_drop_down {
    ul {
      display: none;
    }
  }
}
