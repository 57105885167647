.binary_checkbox_label_level_down {
  i {
    color: #6090C3;
    margin-left: 5px;
    font-size: 1em;
    margin-top: 2px;
  }
}

.binary_filter_all_item, .binary_filter_checkbox_container {
  padding: 12px;
  width: 98%;
  max-height: 280px;
  border: 1px solid #CCC;
  overflow: auto;
  overflow-x: hidden;
  margin-left: 5px;

  span {
    margin: 0;
    font-weight: 400;
  }
}

.clear_all {
  margin-top: 10px;
  margin-left: 10px;
  color: #6090C3;
  cursor: pointer;
  width: 60px;
}

.show_all {
  color: #6090C3;
  margin: 10px 8px 0 8px;
}


.checkbox, .radio {
  margin: 0;
}

.checkbox label {
  padding-left: 0;
}

.binary_filter_search {

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  i {
    float: left;
    background: #EEE;
    color: #666;
    border: 1px #CCC solid;
    border-right: none;
    width: 8%;
    padding: 0.68em;
  }

  input {
    width: 92%;
    padding: 0.5em;
    border-radius: 0px;
    border: 1px #CCC solid;
  }
}

.binary_checkbox_custom {
  font-size: 0.8em;
  margin: 1em 0;

  label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 8px;
    height: 8px;
    border: 1px solid #ddd;
    background: #fff;
  }
}
