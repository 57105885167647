.binary_dropdown {
  border-radius: 2px;
  margin-top: 0px;
  font-weight: 400;
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ccc;

  .binary_dropdown_single {
    & > div > div {
      bottom: 0 !important;
    }
  }

  hr {
    display: none !important;
  }

  svg {
    top: 0px !important;
    height: 18px !important;
    fill: #4E4E4E !important;
  }
}

.binary_dropdown .Select-control {
  border-color: #fff !important;
}

.binary_dropdown_noborder {
  font-weight: 400;

  svg {
    top: 12px !important;
    height: 18px !important;
    fill: #4E4E4E !important;
  }
}

.binary_dropdown_item {
  color: #4E4E4E !important;

  &:hover {
    background-color: rgba(4, 87, 106, 0.16) !important;
  }
}

.binary_dropdown_item_selected {
  color: #4E4E4E !important;
  width: 100% !important;
  background-color: #E0ECF8 !important;

  &:hover {
    background-color: rgba(4, 87, 106, 0.16) !important;
  }
}

.binary_custom_dropdown {
  div {
    z-index: 1099;
  }
}